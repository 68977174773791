import _attribute2 from "./attribute";
import _className2 from "./className";
import _combinator2 from "./combinator";
import _comment2 from "./comment";
import _id2 from "./id";
import _nesting2 from "./nesting";
import _pseudo2 from "./pseudo";
import _root2 from "./root";
import _selector2 from "./selector";
import _string2 from "./string";
import _tag2 from "./tag";
import _universal2 from "./universal";
var exports = {};
exports.__esModule = true;
exports.universal = exports.tag = exports.string = exports.selector = exports.root = exports.pseudo = exports.nesting = exports.id = exports.comment = exports.combinator = exports.className = exports.attribute = void 0;
var _attribute = _interopRequireDefault(_attribute2);
var _className = _interopRequireDefault(_className2);
var _combinator = _interopRequireDefault(_combinator2);
var _comment = _interopRequireDefault(_comment2);
var _id = _interopRequireDefault(_id2);
var _nesting = _interopRequireDefault(_nesting2);
var _pseudo = _interopRequireDefault(_pseudo2);
var _root = _interopRequireDefault(_root2);
var _selector = _interopRequireDefault(_selector2);
var _string = _interopRequireDefault(_string2);
var _tag = _interopRequireDefault(_tag2);
var _universal = _interopRequireDefault(_universal2);
function _interopRequireDefault(obj) {
  return obj && obj.__esModule ? obj : {
    "default": obj
  };
}
var attribute = function attribute(opts) {
  return new _attribute["default"](opts);
};
exports.attribute = attribute;
var className = function className(opts) {
  return new _className["default"](opts);
};
exports.className = className;
var combinator = function combinator(opts) {
  return new _combinator["default"](opts);
};
exports.combinator = combinator;
var comment = function comment(opts) {
  return new _comment["default"](opts);
};
exports.comment = comment;
var id = function id(opts) {
  return new _id["default"](opts);
};
exports.id = id;
var nesting = function nesting(opts) {
  return new _nesting["default"](opts);
};
exports.nesting = nesting;
var pseudo = function pseudo(opts) {
  return new _pseudo["default"](opts);
};
exports.pseudo = pseudo;
var root = function root(opts) {
  return new _root["default"](opts);
};
exports.root = root;
var selector = function selector(opts) {
  return new _selector["default"](opts);
};
exports.selector = selector;
var string = function string(opts) {
  return new _string["default"](opts);
};
exports.string = string;
var tag = function tag(opts) {
  return new _tag["default"](opts);
};
exports.tag = tag;
var universal = function universal(opts) {
  return new _universal["default"](opts);
};
exports.universal = universal;
export default exports;